
/**
 * Accordion.js
 * Controls the behavior of the gp_wagtail_block_library Accordion block 
 */
$(function() {
  $('.__gp__accordion-block__heading-wrapper').on('click', function(e) {
    const accordionIcons = $(this).find('.__gp__accordion-block__heading-button');
    const accordionContent = $(this).next('.__gp__accordion-block__content');
    $(this).parent('.__gp__accordion-block').toggleClass('open')
    accordionIcons.toggleClass('open');
    if (accordionContent.is(':visible')) {
        accordionContent.slideUp();
    } else accordionContent.slideDown();
  })
});
