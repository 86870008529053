$(function () {
  const contentWidthDesktop = 1140;

  function _closeMainMenu() {
    $('.menu-button').removeClass('open');
    $('.main-menu').removeClass('open');
    $('.main-header__search').removeClass('open');
    $('.search-bar__input').attr('placeholder', 'Search learningpower.org');
    $('body').removeClass('overflow-hidden');
  }

  function _toggleSubMenu(subMenu, svg) {
    const subMenuOpen = $(subMenu).is(':visible');
    if (subMenuOpen) {
      $(svg).removeClass('expanded');
      $(subMenu).removeClass('expanded');
    } else {
      $(svg).addClass('expanded');
      $(subMenu).addClass('expanded');
    }
  }

  function _closeAllSubmenus() {
    $('.main-menu__nav-item.parent').each((i, el) => {
      _closeSubmenu({ e: null }, el);
    });
  }

  function _openSubMenu(e, target) {
    if ($(e.target).is('svg')) return;
    const subMenu = $(target).children('.main-sub-menu');
    const svg = $(target).find('svg');
    $(svg).addClass('expanded');
    $(subMenu).addClass('expanded');
  }

  function _closeSubmenu(e, target) {
    if ($(e.target).is('svg')) return;
    const subMenu = $(target).children('.main-sub-menu');
    const svg = $(target).find('svg');
    $(svg).removeClass('expanded');
    $(subMenu).removeClass('expanded');
  }

  // expand sub-nav on hover
  $('.main-menu__nav-item.parent')
    .on('mouseenter', function (e) {
      if ($(window).width() > contentWidthDesktop) {
        _closeAllSubmenus();
        _openSubMenu(e, this);
      }
    })
    .on('mouseleave', function (e) {
      if ($(window).width() > contentWidthDesktop) {
        _closeSubmenu(e, this);
      }
    });

  // for mobile, expand sub-nav on chevron-click
  $('.main-menu__caret').on('click', function (e) {
    e.stopPropagation();
    e.preventDefault();
    const navItem = $(this).parents('.main-menu__nav-item');
    const subMenu = $(navItem).children('.main-sub-menu');
    _toggleSubMenu(subMenu, this);
  });

  // mobile main menu
  $('.menu-button, .menu-backdrop').on('click', function () {
    $('.menu-button').toggleClass('open');
    $('.main-menu').toggleClass('open');
    $('.main-header__search').toggleClass('open');
    $('.search-bar__input').attr('placeholder', 'Search learningpower.org');
    $('body').toggleClass('overflow-hidden');
  });

  // search icon animation
  $('.search-buttons__button').on('click', function () {
    $('.search-buttons__button').toggleClass('open');

    if ($('.main-header__search').is(':visible')) {
      $('.main-header__search').slideUp(100);
    } else {
      $('.main-header__search').slideDown(100, () => {
        $('#menuSearchBar').trigger('focus');
      });
    }
  });
  $(window).resize(function () {
    if ($(window).width() > contentWidthDesktop) {
      _closeAllSubmenus();
      _closeMainMenu();
    }
  });
});
