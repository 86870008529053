function _disableFormInputs(form) {
  form.find('input, textarea, button').prop('disabled', true);
}

function _enableFormInputs(form) {
  form.find('input, textarea, button').prop('disabled', false);
}

function _hideFieldErrors(form) {
  form.find('.field-errors').empty();
  form.find('.field-errors').hide();
  form.find('.input-error').removeClass('input-error');
}

export function animateAlert(form, msg, type, duration) {
  _hideFieldErrors(form);
  const fadeSpeed = 200;
  const alertElement = $('<div/>', { text: msg }).addClass(['alert', `alert-${type}`]);
  let alertContainer = $('body').find('.alert-container');
  if (alertContainer.length === 0) {
    alertContainer = $('<div/>', { class: 'alert-container' }).appendTo('body');
  }

  // animate in
  setTimeout(() => {
    alertContainer.empty();
    alertElement.appendTo(alertContainer);
    alertContainer.addClass('open');
    alertElement.fadeTo(fadeSpeed, 1);
  }, fadeSpeed);

  // enable other disabled forms on page and clear old timeouts
  if (window.alertTimeout && window.disabledForm) {
    clearTimeout(window.alertTimeout);
    window.alertTimeout = null;
    _enableFormInputs(window.disabledForm);
    window.disabledForm.fadeTo(fadeSpeed, 1);
    window.disabledForm = null;
  }

  window.disabledForm = form; // saving disabled form to window
  form.fadeTo(fadeSpeed, 0.3, () => {
    _disableFormInputs(form);
  });

  // animate out
  window.alertTimeout = setTimeout(() => {
    _enableFormInputs(form);
    alertContainer.removeClass('open');
    alertElement.fadeTo(fadeSpeed, 0, () => {
      alertElement.hide();
    });
    form.fadeTo(fadeSpeed, 1);
    window.alertTimeout = null;
    window.disabledForm = null;
  }, duration);
}

export function handleFormErrors(responseText, form) {
  _hideFieldErrors(form);
  try {
    const errorFields = JSON.parse(responseText);
    for (const field in errorFields) {
      if (errorFields.hasOwnProperty(field)) {
        const fieldErrors = errorFields[field];
        const fieldElement = form.find(`input[name='${field}'], textarea[name='${field}']`);
        fieldElement.addClass('input-error');
        const fieldParent = fieldElement.closest('.form-group');

        let errorsUl = fieldParent.find('.field-errors');
        if (errorsUl.length === 0) {
          errorsUl = $(document.createElement('ul'));
          errorsUl.addClass('field-errors');
          fieldParent.append(errorsUl);
        }
        errorsUl.show();
        for (let i = 0; i < fieldErrors.length; i++) {
          const errorMsg = fieldErrors[i];
          const errorListItem = `<li>${errorMsg.message}</li>`;
          errorsUl.append(errorListItem);
        }
      }
    }
  } catch (error) {
    if (typeof Sentry !== 'undefined') {
      Sentry.captureMessage('Failed in handleFormErrors');
      Sentry.captureException(error);
    }
    console.warn(error);
  }
}

function _setCookie(cname, cvalue) {
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);
  document.cookie = cname + '=' + cvalue + ';expires=' + expiryDate.toUTCString();
}

function _getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function _verifyAgeOverThirteen() {
  _setCookie('ageverifiedtoken', 'y');
}

export function checkAgeIsVerified() {
  const ageVerifiedToken = _getCookie('ageverifiedtoken');
  if (ageVerifiedToken) {
    return true;
  }
  return false;
}

export function showAgeVerification() {
  const msg =
    'To ensure the safety of children, we require users to be at least 13 years of age or have obtained parental consent before providing any personal information on our website, in accordance with COPPA regulations.';
  let confirmContainer = $('body').find('.confirm-container');
  if (confirmContainer.length === 0) {
    confirmContainer = $('<div/>', { class: 'confirm-container' }).appendTo('body');
  }
  const successElement = $('<div/>', { text: 'Thank you! Your age has been verified ' }).addClass('confirm-sucess');
  const successCheckmark = $('<i/>').addClass(['fa', 'fa-check-circle']).appendTo(successElement);
  const confirmElement = $('<div/>').addClass('confirm');
  const confirmHeader = $('<div/>', { text: 'Age Verification:' }).addClass('confirm-header').appendTo(confirmElement);
  const closeButton = $('<button/>').addClass(['fas', 'fa-times', 'confirm-close']).appendTo(confirmHeader);
  const confirmMsg = $('<div/>', { text: msg }).addClass('confirm-msg').appendTo(confirmElement);
  const confirmActions = $('<div/>').addClass('confirm-actions').appendTo(confirmElement);
  const disagreeButton = $('<button/>', { text: 'I am under 13 years old' })
    .addClass('secondary-button')
    .appendTo(confirmActions);
  const agreeButton = $('<button/>', { text: 'I am 13 or older' }).addClass('basic-button').appendTo(confirmActions);
  confirmContainer.click(function (e) {
    if (e.target !== this) return;
    confirmContainer.removeClass('open');
  });
  closeButton.click(function (e) {
    confirmContainer.removeClass('open');
  });
  agreeButton.click(function (e) {
    _verifyAgeOverThirteen();
    confirmActions.empty();
    confirmMsg.empty();
    successElement.appendTo(confirmMsg);
    setTimeout(() => {
      confirmContainer.removeClass('open');
    }, 1500);
  });
  disagreeButton.click(function (e) {
    confirmContainer.removeClass('open');
  });
  confirmContainer.empty();
  confirmElement.appendTo(confirmContainer);
  setTimeout(() => {
    confirmContainer.addClass('open');
  }, 200);
}
