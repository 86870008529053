import { animateAlert, checkAgeIsVerified, handleFormErrors, showAgeVerification } from './genericFormMethods';

$(document).ready(() => {
  const submitButton = $('.event-signup-form-block__submit-button');
  submitButton.click(function (e) {
    e.preventDefault();
    const form = $(this).parents('form');
    handleFormSubmit(form);
  });

  function handleFormSubmit(form) {
    const data = form.serializeArray();
    const url = '/contact/event/';
    $.ajax({
      type: 'POST',
      url,
      data,
      success: (response) => handleFormSuccess(response, form),
      error: (error) => handleFormError(error, form)
    });
  }

  function handleFormSuccess(response, form) {
    form.find('input[type=text], textarea').val('');
    animateAlert(form, response, 'success', 6000);
  }

  function handleGeneralError(form) {
    const msg = 'Oops, we encountered an error.  Please try again later.';
    animateAlert(form, msg, 'danger', 3000);
  }

  function handleFormError(error, form) {
    if (error.status === 422) {
      handleFormErrors(error.responseText, form);
    } else {
      handleGeneralError(form);
      console.error(error);
    }
  }

  const formInputs = $('.event-signup-form-block__form-check, .event-signup-form-block__form-input');
  formInputs.focus(function (e) {
    if (!checkAgeIsVerified()) {
      showAgeVerification();
      e.target.blur();
    }
  });
});
