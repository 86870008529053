import getCookie, { csrfSafeMethod } from './util/getCookie';

// Some general js goes here.
$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    }
  }
});
