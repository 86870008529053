/**
 * videoHeroBlock.js
 * Handles Video.js functionality
 * Video.js docs @ https://docs.videojs.com/
 */
function hideAndShow(hideElement, showElement) {
  hideElement.classList.add('hidden');
  showElement.classList.remove('hidden');
}

$(document).ready(() => {
  // if there are iframes that contain brightcove videos, we will loop through and find them
  // NOTE: we are removing the actual iframe here and using the advanced brightcove embed code
  // 1) this will allow us to have a fallback of an iframe if javascript is tempermental
  // 2) this allows the wagtail user to enter the same iframe embed code that
  // they would add to the two-column block, which was requested.
  $('.video-hero-block iframe').each((i, iframe) => {
    if (iframe.src.includes('brightcove.net')) {
      const url = new URL(iframe.src);
      const account = url.pathname.split('/')[1];
      const videoId = url.search.split('videoId=')[1];
      const videoParent = $(iframe).closest('.video-hero-block__content');
      const playButton = videoParent.find('.video-hero-block__video-play');
      const pauseButton = videoParent.find('.video-hero-block__video-pause');
      const videoWrapper = videoParent.find('.video-hero-block__video')[0];

      videoWrapper.classList.add('loading');

      const videojsEl = document.createElement('video-js');
      videojsEl.id = 'heroVideoID';
      videojsEl.setAttribute('data-account', account);
      videojsEl.setAttribute('data-player', 'default');
      videojsEl.setAttribute('data-embed', 'default');
      videojsEl.setAttribute('data-video-id', videoId);
      videoWrapper.append(videojsEl);
      const bcScriptTag = document.createElement('script');
      bcScriptTag.setAttribute('src', `https://players.brightcove.net/${account}/default_default/index.min.js`);
      videoWrapper.append(bcScriptTag);

      iframe.remove();

      bcScriptTag.onload = () => {
        videojs.getPlayer('heroVideoID').ready(function () {
          videoWrapper.classList.remove('loading');
          this.muted(true);
          this.play();
          hideAndShow(playButton[0], pauseButton[0]);

          this.on('ended', () => {
            // loop
            this.play();
          });
          if (pauseButton) {
            pauseButton.on('click', () => {
              this.pause();
              hideAndShow(pauseButton[0], playButton[0]);
            });
          }
          if (playButton) {
            playButton.on('click', () => {
              this.play();
              hideAndShow(playButton[0], pauseButton[0]);
            });
          }
        });
      };
    }
  });

  // alternatively, we will look for video element with id === video-hero
  $('video#video-hero').each((i, videoEl) => {
    window.videojs('video-hero').ready(function () {
      const videoParent = $(videoEl).closest('.video-hero-block__content');
      const playButton = videoParent.find('.video-hero-block__video-play')[0];
      const pauseButton = videoParent.find('.video-hero-block__video-pause')[0];
      const videoWrapper = videoParent.find('.video-hero-block__video')[0];

      this.on('ended', () => {
        this.play();
      });

      this.on('error', () => {
        // if there is some error with the video, fade it out:
        videoWrapper.classList.add('loading');
      });

      if ('platform' in window.navigator && /iPad|iPhone|iPod/.test(window.navigator.platform)) {
        // For iOS devices, autoplay may not be respected if the element is not
        // visible. So explicitly make it visible right away:
        hideAndShow(playButton, pauseButton);
        videoWrapper.classList.remove('loading');
      } else {
        // For other devices, though, there can be some flickering of the video
        // controls as the video first loads - hide it until it really is loaded and
        // playing:
        this.one('playing', () => {
          hideAndShow(playButton, pauseButton);
          videoWrapper.classList.remove('loading');
        });
      }

      if (playButton) {
        playButton.addEventListener('click', () => {
          this.play();
          hideAndShow(playButton, pauseButton);
        });
      }

      if (pauseButton) {
        pauseButton.addEventListener('click', () => {
          this.pause();
          hideAndShow(pauseButton, playButton);
        });
      }
    });
  });
});
