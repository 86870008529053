import makeFullscreen from '../../../gp_wagtail_block_library/static/js/util/requestFullscreen';
/**
 * gameModal.js
 * Resposible for displaying the game modal
 */
$(function () {
  $('[data-tile-grid-external-link]').on('click', function (e) {
    e.preventDefault();

    // creating iframe
    const iframeContainer = document.createElement('div');
    iframeContainer.classList.add('game-modal__body', 'open');

    const iframe = document.createElement('iframe');
    iframe.src = e.target.closest('a').href;
    iframe.style.cssText = `
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    `;
    const fullScreenBtn = document.createElement('i');
    fullScreenBtn.classList.add('fas', 'fa-arrows-alt', 'game-modal__fullscreen-btn');

    const closeBtn = document.createElement('i');
    closeBtn.classList.add('fas', 'fa-times', 'game-modal__close-btn');
    iframeContainer.appendChild(fullScreenBtn);
    iframeContainer.appendChild(closeBtn);
    iframeContainer.appendChild(iframe);

    $(fullScreenBtn).on('click', function () {
      makeFullscreen(iframe);
    });

    // creating underlay
    const underlay = document.createElement('div');
    underlay.classList.add('game-modal__underlay', 'open');

    // adding game to page
    document.body.appendChild(underlay);
    document.body.appendChild(iframeContainer);
    setTimeout(() => {
      // timeout allows for the elements to appended before animation begins
      $(iframeContainer).animate({ opacity: 1 }, 325);
      $(underlay).animate({ opacity: 0.8 }, 300);
    }, 200);

    // clicking the underlay or close btn will close the modal and remove game
    $(underlay)
      .add(closeBtn)
      .on('click', function () {
        $(iframeContainer).animate({ opacity: 0 }, 300);
        $(underlay).animate({ opacity: 0 }, 300);
        setTimeout(() => {
          underlay.classList.remove('open');
          iframeContainer.classList.remove('open');
          underlay.remove();
          iframeContainer.remove();
        }, 500);
      });
  });
});
