/**
 * makeFullscreen
 * @param {HTMLElement} el - the DOM node that will request fullscreen.
 */
export default function makeFullscreen(el) {
  if (el.requestFullScreen) {
    el.requestFullScreen();
  } else if (el.webkitRequestFullScreen) {
    el.webkitRequestFullScreen();
  } else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  }
}
