/**
 * videoModal.js
 * Resposible for displaying the video modal
 */
$(function() {
    // Clicking element with attribute data-modal-toggle
    $('[data-modal-toggle]').on('click', function (e) {
        e.stopPropagation()
        $('body').addClass('overflow-hidden');

        const body = $(this).closest('article').find('.__gp__video-modal__body')
        const underlay = $(this).closest('article').find('.__gp__video-modal__underlay')
        underlay.addClass('open');
        underlay.animate({ opacity: 0.8 }, 300);
        body.addClass('open');
        body.animate({ scale: 1, opacity: 1 }, 325)
    });


    // Clicking modal underlay closes modal and stops playback
    $('.__gp__video-modal__underlay').on('click', function() {
        const iframe = $(this).siblings('.__gp__video-modal__body').children('iframe');
        closeModal(iframe);
    });

    // This and the above are handled separately because of the differences in
    // how they a related to the iframe.
    // Clicking "x" closes modal and stops playback
    $('.__gp__video-modal__close-btn').on('click', function() {
        const iframe = $(this).siblings('iframe');
        closeModal(iframe);
    })
});

function closeModal(iframe) {
    // Reseting the "src" attribute of the iframe effecitevly stops playback.
    // Not great, but iframes are deliberately impenetrable.
    $('.__gp__video-modal__body').animate({ scale: 0.7, opacity: 0 }, 150, () => {
        $('.__gp__video-modal__body').removeClass('open');
        iframe.attr('src', iframe.attr('src'));
    })
    $('.__gp__video-modal__underlay').animate({ opacity: 0 }, 200, () => {
        $('.__gp__video-modal__underlay').removeClass('open');
        iframe.attr('src', iframe.attr('src'));
    });
    $('body').removeClass('overflow-hidden');
}
