/**
 * coordinatorContact.js
 * Uses Awesomoplete.js (https://leaverou.github.io/awesomplete/) to create an autocomplete widget.
 */

import { animateAlert, checkAgeIsVerified, handleFormErrors, showAgeVerification } from './genericFormMethods';

$(document).ready(() => {
  const submitButton = $('button.coordinator-contact-form-block__submit-button');
  const districtList = $('#id_school_district')[0];
  const districtErrorDiv = $('.coordinator-contact-form-block__form-error-message.district');
  if ($('#districtList').length) {
    new Awesomplete(districtList, { list: '#districtList' });
    submitButton.on('click', function (e) {
      e.preventDefault();
      const thisForm = $(this).parents('form');
      handleSubmitContactForm(thisForm);
    });
  }

  //Handlers
  function handleSubmitContactForm(form) {
    const contactForm = form.serializeArray();
    const contactId = submitButton.attr('data-id').valueOf();
    const url = `/contact/coordinator/${contactId}/`;
    $.ajax({
      type: 'POST',
      url: url,
      data: contactForm,
      success: (response) => handleContactFormSuccess(response, form),
      error: (data) => handleContactFormError(data, form)
    });
  }

  function handleContactFormSuccess(data, form) {
    if (data.length > 0) {
      // If there are form errors data will have a size
      handleFormErrors(data, form);
    } else {
      form.find('input, textarea').val('');
      const msg =
        'Your request to schedule a lesson has been received. Your Education Coordinator will contact you to coordinate details.';
      animateAlert(form, msg, 'success', 6000);
    }
  }

  function handleContactFormError(data, form) {
    const msg = 'Oops, we encountered an error.  Please try again later.';
    animateAlert(form, msg, 'danger', 3000);
    console.log(data);
  }

  const formInputs = $('.coordinator-contact-form-block__form-input');
  formInputs.focus(function (e) {
    if (!checkAgeIsVerified()) {
      showAgeVerification();
      e.target.blur();
    }
  });
});
