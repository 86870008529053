export default $(function() {
  const HONEYPOT_SELECTOR = '.topyenoh'
  const HONEYPOT_CSS = {
    display: 'none',
    position: 'absolute',
    left: '9999px'
  }
  const FORM_FIELDS = 'input, select'
  const VERIFIER_FIELD = 'input[name=company_company_email]'
  const VERIFIER_VALUE = 'nine'

  /*
    Add concealing styles to honeypot container.
  */
  $(HONEYPOT_SELECTOR).css(HONEYPOT_CSS)

  function updateHoneypotForms() {
    $('form').each(function () {
      const $this = $(this)
      const $honeypot = $this.find(HONEYPOT_SELECTOR)
      /*
        If we can find honeypot inputs, add an event listener
        so that human interactions with the form will cause
        the VERIFIER_FIELD to get filled with the VERIFIER_VALUE.
      */
      if ($honeypot.length) {
        const $verifierFields = $honeypot.find(VERIFIER_FIELD)

        $this.find(FORM_FIELDS).on('focus', () => {
          $verifierFields.val(VERIFIER_VALUE)
        })
      }
    })
  }

  updateHoneypotForms()
  // Handle dynamically-loaded honeypot forms.
  document.addEventListener('htmx:afterRequest', updateHoneypotForms)
});
