import Awesomplete from 'awesomplete';

/**
 * coordinatorSearch.js
 * Uses Awesomoplete.js (https://leaverou.github.io/awesomplete/) to create an autocomplete widget
 * for finding coordinators by district.
 */
$(() => {
  const $input = $('.coordinator-search-block__search-input');
  if ($input.length != 0) {
    const input = $input[0];
    const awesomplete = new Awesomplete(input);

    $.ajax('/districts-coordinators/', {
      type: 'GET',
      success: (data) => handleGetDistrictsSuccess(data),
      error: handleGetDistrictsError
    });

    $input.on('awesomplete-selectcomplete', handleEntitySelected);

    function handleGetDistrictsSuccess(data) {
      try {
        awesomplete.list = JSON.parse(data);
      } catch (error) {
        Sentry.captureMessage('Failed to parse JSON in handleGetDistrictsSuccess');
        Sentry.captureException(error);
        console.warn(error);
      }
    }

    function handleGetDistrictsError(error) {
      console.warn(error);
    }

    function handleEntitySelected(e) {
      const entity_name = e.target.value;
      $.ajax(`/districts-coordinators/${entity_name}/`, {
        type: 'GET',
        success: handleGetCoordinatorSuccess,
        error: handleGetCoordinatorError
      });
    }

    function handleGetCoordinatorSuccess(data) {
      try {
        const coordinator = JSON.parse(data);
        $('.coordinator-search-block__contact-wrapper').css('display', 'none');
        $('.coordinator-search-block__error').css('display', 'none');
        if (coordinator.length === 0) {
          $('.coordinator-search-block__error').html('<h2>No Coordinator found</h2>').css('display', 'flex');
          return;
        }

        const contactMessage = 'Schedule a visit from ';
        const coordinatorGivenName = coordinator.name.split(' ')[0];
        $('.coordinator-search-block__image').attr('src', coordinator.portrait);
        $('.coordinator-search-block__contact-name').html(coordinator.name);
        if (coordinator.bio_page_url) {
          $('.coordinator-search-block__contact-name, .coordinator-search-block__image').wrap(
            `<a href="${coordinator.bio_page_url}"></a>`
          );

          if (coordinator.has_contact_form) {
            $('.coordinator-search-block__contact-button')
              .css('display', 'inline-block')
              .text(contactMessage + coordinatorGivenName)
              .attr('href', `${coordinator.bio_page_url}#request-a-visit`);
          }
        }
        $('.coordinator-search-block__contact-blurb').html(coordinator.blurb);
        $('.coordinator-search-block__contact-wrapper').css('display', 'flex');
      } catch (error) {
        Sentry.captureMessage('Failed in handleGetCoordinatorSuccess');
        Sentry.captureException(error);
        console.warn(error);
      }
    }

    function handleGetCoordinatorError(error) {
      console.warn(error);
    }
  }
});
